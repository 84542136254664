@import './../theme/colors.scss';
@import './../theme/sizes.scss';

#menu {
    position: fixed;
    left: 0;
    top: 0;
    width: $menu_width;

    &.active {
        width: $menu_width_expanded;
        bottom: 0;
    }

    div.header {
        position: relative;
        left: 0;
        top: 0;
        height: $menu_headerHeight;
        border-bottom: 1px solid $menuBorderColor;
        background-color: $white;

        &.active {
            background-color: $grayDark;
        }

        span {

            &.logoBox {
                position: absolute;
                left: 20px;
                top: 25px;
                img {
                    border-radius: 5px;
                    max-width: 64px;
                }
            }

            &.toggleBox {
                position: absolute;
                left: 120px;
                top: 42px;

                button {
                    width: 35px;
                    height: 35px;
                    padding: 0;

                    &.active {
                        margin-right: 20px;
                    }

                    &.menuToggleButton {
                        border: 1px solid $grayMedium;
                        background-color: $whiteDark;
                        border-radius: 4px;

                        * {
                            color: $grayMedium;
                            font-size: 16pt;
                        }

                        &.active {
                            background-color: $grayMedium;
                            border-color: $grayLight;

                            * {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    div.body {
        display: none;

        &.active {
            position: absolute;
            left: 0;
            top: $menu_headerHeight;
            width: $menu_width_expanded;
            bottom: 0;
            display: block;
            background-color: $grayDark;
            padding: 20px 0;

            * {
                color: $grayLight;
                font-size: 12pt;
            }

            a {
                text-decoration: none;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    padding: 10px 0 10px 10px;
                    border-bottom: 1px solid $grayMedium;

                    .itemIcon {
                        font-size: 14pt;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    a {
        text-decoration: none;
        font-weight: normal;
    }
}

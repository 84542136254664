@import '../theme/colors.scss';

.congruence-ok {
    background-color: green;
    color: white;
    font-weight: bold;
}
.congruence-warning-light {
    background-color: yellow;
    color: black;
    font-weight: bold;
}
.congruence-warning-dark {
    background-color: orange;
    color: black;
    font-weight: bold;
}
.congruence-warning-fatal {
    background-color: red;
    color: white;
    font-weight: bold;
}

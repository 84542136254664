/** --- defaults --- */
@import './../index.scss';

#loginPage,
#passwordRequestPage,
#setPasswordPage,
#fourOfourPage
{
    padding-top: 30px;

    img {
        width: 160px;
        max-width: 90%;
        border-radius: 5px;
    }

    div {
        text-align: center;

        /** the process info container */
        &.loginProcessInfoBox {
            display: none;

            &.active {
                display: block;
            }

            h2 {
                font-size: 2rem;
                color: red;
            }

            p {
                font-size: 1.5rem;
            }
        }
    }

    .submit { -webkit-appearance: none; }
    input {
        -webkit-appearance: none;
        text-align: center;

        &.input-text {
            width: auto !important;
        }
    }

    #spinner {
        margin-bottom: 20px;
    }

    ul li {
        list-style: none;
    }

}


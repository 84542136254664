/** ---
    THIS FILE IS NOT USED DIRECTLY!!!
    The restricted area uses restricted.scss,
    login and password-request /-reset uses public.scss!
    --- */

/** --- basics --- */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: fixed;
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    position: relative;
}

#content {
    overflow-y: scroll;
    height: calc(100vh - 120px);
}

/** --------------------------------------- */
/**                 THEME                   */
/** --------------------------------------- */

/** --- layers --- */
@import './theme/z-indexes.scss';

/** --- colors --- */
@import './theme/colors.scss';

/** --- table --- */
@import './theme/table.scss';

/** --- form --- */
@import './theme/form.scss';
@import './theme/buttons.scss';

/** --- cards --- */
@import './theme/cards.scss';

/** --- list --- */
@import './theme/list.scss';

/** --------------------------------------- */
/**              COMPONENTS                 */
/** --------------------------------------- */

/** --- spinner --- */
@import './components/spinner.scss';

/** --- overlay --- */
@import './components/overlay.scss';

/** --------------------------------------- */
/**              OVERRIDES                  */
/** --------------------------------------- */

h1 {
    font-size: 18pt;
    color: $mediMagenta;
}
h2 {
    font-size: 14pt;

    &.magenta {
        color: $mediMagenta;
    }
}
h3 {
    font-size: 12pt;
}

.frozen {
    //opacity: 0.25 !important;
    pointer-events:none;
}

.hidden {
    display: none;
}

a {
    color: $grayDark;
    text-decoration: underline;

    &:active, &:hover {
        color: $mediMagenta;
    }
}

.icon-success {
    color: green;
    font-size: 18pt;
}

.icon-error {
    color: red;
    font-size: 18pt;
}

@import './../theme/colors.scss';

#customerList {
    .btn {
        &.customerListActionButton {
            width: 220px;
            font-size: 14pt;
            background-color: $whiteDark;

            svg {
                font-size: 24pt;

                * {
                    font-size: 24pt;
                }
            }
        }
    }
}

.customerAddress {

    h2 {
        padding: 0;
        margin: 2px 5px 5px 5px;
        font-size: 16pt;
    }

    p {
        font-size: 12pt;
        margin: 5px;
        line-height: initial;
    }

    &.customerListItem {
        max-width: 300px;
    }
}


@media all and (max-width: 900px) {
    .customerAddress {
        h2 {
            font-size: 12pt;
        }
        p {
            font-size: 10pt;
        }
    }
}
@import './colors.scss';

input {
    outline: none !important;

    &.input-text {
        border: 1px solid $grayDark;
        border-radius: 4px;
        padding: 5px 10px;
        font-size: 14pt;
        width: 100%;

        &:checked {
            border-color: $mediMagentaDark;
        }

        &:focus {
            border-color: $mediMagentaDark;
            box-shadow: 0 0 0 0.25rem $mediMagentaShadow;
        }
    }

    &[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 4px 0 -4px 0;

        border: 1px solid $grayMedium;
        border-radius: 5px;
        width: 21px;
        height: 21px;

        &:checked {
            background-color: $white;
            border-color: $grayDark;
            background-image: url('../../../public/images/checkbox-bg.png');
            background-size: cover;
        }
    }
}

@import './colors.scss';

table {
    width: 100%;

    thead {
        th {
            td {
                padding: 5px 10px;
                font-weight: bold;
            }
        }
    }

    tbody {

        tr {

            td {
                //border: 1px dotted red;
                vertical-align: top;
                padding: 2px 5px;

                &.title {
                    font-weight: bold;
                }
            }
        }
    }

    &.innerGrid {

        border-spacing: 2px;
        border-collapse: separate;

        thead {
            tr {
                th {
                    padding: 5px 10px;
                    border-right: 1px dotted $grayDark;
                    border-bottom: 1px dotted $grayDark;

                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 5px 10px;
                    border-right: 1px dotted $grayDark;
                    border-bottom: 1px dotted $grayDark;
                    &:last-child {
                        border-right: none;
                    }
                }
                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    td {
        &.centered {
            text-align: center;
        }
    }
}

/** --- menu stuff --- */
$menu_headerHeight: 120px;
//$menu_width: 160px;
$menu_width: 250px;
$menu_width_expanded: 250px;

/** --- header stuff --- */
$header_left: $menu_width;
$header_height: $menu_headerHeight;

/** --- app content stuff --- */
$app_content_left: 0;
$app_content_top: $header_height;

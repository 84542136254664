/* --- main colors --- */
$mediMagenta: #e6007e;
$mediMagentaDark: #B40063;
$mediMagentaShadow: #FED9ED;
$mediMagentaShadowLight: #FFEFF8;

$grayDark: #323339;
$grayMedium: #464b66;
$grayLight: #ddd;
$grayBright: #fdfdfd;

$white: #fff;
$whiteDark: #fbfbfb;

$black: #000;

$red: red;
$redDark: #CF0000;
$redMedium: #FF5757;
$redLight: #FFCBCB;

$warningMedium: #FFD100;
$warningLight: #FEF5E7;
$warningDark: darken($warningMedium, 10);
$warningFont: $grayMedium;

/* --- font colors --- */
$fontDark: #303030;

/** --- other stuff --- */
$onlineBackground: #22A81E;
$onlineFont: #fff;
$onlineBorder: #aaa;
$offlineBackground: #DC143C;
$offlineFont: #fff;
$offlineBorder: transparent;

/** --- tables and stuff --- */
$even: #fff;
$odd: #eee;
$evenBorder: $grayMedium;
$oddBorder: $grayDark;

/** --- scan item status --- */
$greaterStockBorder: orange;
$greaterStockBackground: #FEF5E7;
$lowerStockBorder: red;
$lowerStockBackground: #FDEDEC;
$equalStockBorder: green;
$equalStockBackground: #EAFAF1;
$notInStockBorder: black;
$notInStockBackground: #eee;
$defaultStockBorder: $grayDark;
$defaultStockBackground: #fff;

/** --- menu stuff --- */
$menuBorderColor: $grayDark;

/** --- header stuff --- */
$headerBorderColor: $menuBorderColor;

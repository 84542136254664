@import "./../theme/colors.scss";

.scan-form {
  margin-top: -10px;
  .scan-form-header {
    position: fixed;
    width: 100%;
    padding: 20px 40px 20px 0;
    z-index: 1;
    background-color: white;
  }

  .scan-form-body {
    padding-top: 120px;
    scroll-margin-top: 500px;
  }
}

@import './../theme/colors.scss';
@import './../theme/sizes.scss';

#header {
    position: fixed;
    left: $header_left;
    height: $header_height;
    right: 0;
    border-bottom: 1px solid $headerBorderColor;
    background-color: $white;

    span {
        width: 100px;
        text-align: center;

        &.onlineInfoBox {
            position: absolute;
            right: 10px;
            top: 10px;
            border: 1px solid $grayMedium;
            border-radius: 5px;
            padding: 2px 6px;
            font-size: 0.8rem;
            font-weight: bold;

            &.online {
                background-color: $onlineBackground;
                border-color: $onlineBorder;
                color: $onlineFont;
            }

            &.offline {
                background-color: $offlineBackground;
                border-color: $offlineBorder;
                color: $offlineFont;
                text-transform: uppercase;
            }
        }

        &.appVersionNumberInfoBox {
            position: absolute;
            right: 10px;
            top: 80px;
            font-size: 10pt;
            padding: 0 6px;
        }

        &.testModusInfoBox {
            position: absolute;
            right: 10px;
            top: 50px;
            font-size: 10pt;
            padding: 0 6px;
            text-transform: uppercase;
            font-weight: bold;
            color: $mediMagenta;
        }
    }

    button {
        &.btn {
            &.refreshStorageButton {
                width: 140px;
                font-size: 11pt;
                text-transform: uppercase;
                margin-top: 16px;

                svg {
                    margin: 4px;
                }
            }
        }
    }

    #spinner {
        margin-top: 30px;
    }

    div.searchBar {
        margin: 36px 0;

        input {
            width: 100%;
            border-radius: 4px;
        }
    }
}

@media all and (max-width: 900px) {
    #header {
        button {
            &.btn {
                &.refreshStorageButton {
                    width: 95px;
                    font-size: 8pt;
                }
            }
        }
    }
}
/** --- defaults --- */
@import './../index.scss';
@import './../theme/colors';
@import './../theme/sizes';

#content {
    position: relative;
    left: $app_content_left;
    top: $app_content_top;
    min-height: calc(100% - #{$app_content_top} );
    right: 0;
    padding: 10px 20px 40px 20px;

    //border: 1px solid blue;
}